import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <Link to="/">Home</Link>
      </li>

      <li>
        <Link to="/about-me">About</Link>
      </li>
      {/* <li className="has-droupdown">
        <Link to="#">Blog</Link>
      </li> */}

      {/* <li>
        <Link to="/portfolio-three-column">Portfolio</Link>
      </li> */}

      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </ul>
  );
};
export default Nav;
