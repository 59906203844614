import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiPhone, FiMapPin, FiMail } from "react-icons/fi";
import VfxRGB from "../../components/VfxRGB";

const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30 align-items-center">
          <div className="col-lg-5">
            <div className="thumbnail">
              <VfxRGB
                className="faded faded-top"
                width={550}
                height={550}
                url="./images/about/about-1.png"
                alt="Mehdi Rismanchi Full Stack Developer"
                style={{ borderRadius: "15px" }} // Adds rounded corners with 15px radius
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  Mehdi Rismanchi <br />{" "}
                  <Typed
                    className="theme-gradient"
                    strings={[
                      "Full Stack Developer",
                      "Web and Mobile Developer",
                      "Python, Django, React",
                      "Vue.js, Azure Specialist",
                    ]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <p>
                  Experienced <strong>Full Stack Developer</strong> specializing
                  in building efficient, scalable web and mobile applications.
                  Proficient in modern frameworks like{" "}
                  <b>
                    Python, Django, React, Vue.js, React Native, and Next.js
                  </b>
                  , with strong skills in backend technologies and cloud
                  services such as <b>Microsoft Azure</b> and <b>AWS</b>.
                </p>
                <p>
                  With over a decade of experience, I create robust solutions
                  using <b>PostgreSQL, MySQL, Docker, Nginx, Proxmox</b>, and more. My
                  expertise extends to managing complex infrastructures and
                  deployment pipelines, ensuring high performance and
                  scalability.
                </p>
                <ul className="contact-address">
                  <li>
                    <FiMail /> rismanchi@live.com
                  </li>
                  <li>
                    <FiPhone /> +31 0658959196
                  </li>
                  <li>
                    <FiMapPin /> Netherlands
                  </li>
                </ul>
                <div className="download-button mt--20">
                  <Link className="btn-read-more" to="/about-me">
                    <span>Read More About Me</span>
                  </Link>
                </div>
                {/* Get in Touch Button */}
                <div className="contact-button mt--20">
                  <Link className="btn-read-more" to="/contact">
                    <span>Get in Touch</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
